<template>
  <div>
    <div ref="form" class="table-biao computer_right_cont_box">
      <div class="thead flex-col">
        <div class="bd11 flex-col">
          <div class="block13 flex-row">
            <span class="td-width left-title">{{ untia }}</span>
            <span class="td-width zhongjian" v-for="(item, index) in years" :key="index">
              <span class="td-width-span td-width-txt span_"
                >{{ item }}</span>
              <span class="td-width-span td-width-txt span_" 
                >{{
                  $route.name == "CreditAssessment" ? "Change" : "Change (%)"
                }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="table-tbody">
        <div class="tbody flex-col" v-for="(items, index) in profitablitylist" :key="index">
          <div class="bd11 flex-col">
            <div class="block13 flex-row">
              <span class="td-width thead-title left-title">
                {{ items.name }}
                <span></span>
              </span>
              <span class="td-width zhongjian" v-for="(item, index) in items.lists" :key="index"
                v-show="indexlist.includes(index)">
                <span class="td-width-span td-width-txt">{{ item.value }}</span>
                <span class="td-width-span td-width-txt">
                  <span>{{
                    $route.name == "CreditAssessment"
                      ? item.changes == "n.a."
                        ? " "
                        : ""
                      : item.changes
                  }}</span>
                  <!-- <img style="height: 20px; width: 15px" v-if="$route.name == 'CreditAssessment' ? (item.status == '0' ? true : false) : false" src="../../../../assets/img/henggang.png" alt="" /> -->
                  <!-- <img v-if="item.status == '1'" src="@/assets/img/shangshen.png" alt="" /> -->
                  <svg v-if="item.status == '1'" t="1718793340903" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="4664" width="1.14rem" height="1.14rem">
                    <path
                      d="M512.687 84.864l-261.28 348.26h170.341v509.164h181.866v-509.164h170.341l-261.265-348.26zM512.687 84.864z"
                      fill="#55B419" p-id="4665"></path>
                  </svg>
                  <!-- <img v-if="item.status == '2'" src="@/assets/img/xiajiang.png" alt="" /> -->
                  <svg v-if="item.status == '2'" t="1718793413264" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="4901" width="1.14rem" height="1.14rem">
                    <path
                      d="M511.313 939.136l261.28-348.26-170.341 0 0-509.164-181.866 0 0 509.164-170.341 0 261.265 348.26zM511.313 939.136z"
                      fill="#FF6A6A" p-id="4902"></path>
                  </svg>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="createDialog" custom-class="dialog_normal" @close="Cancel()" :append-to-body="true">
      <div class="detail_cont">
        <h3>Please Select The Year to Display</h3>
        <div class="dialog_detail_next-title">
          Select Up to Three Years
        </div>
        <el-checkbox-group v-model="checkedCities" :max="3">
          <el-checkbox-button v-for="city in yearslist" :label="city" :key="city">{{ city }}</el-checkbox-button>
        </el-checkbox-group>

        <div class="btn_box flex_center_between_box">
          <button class="btn_export cansel" @click="Cancel()">Cancel</button>
          <button class="btn_export" :disabled="checkedCities.length != 3" @click="checkYear">
            Select
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    profitablitylist: {
      type: Array,
      default: function () { },
    },
    yearslist: {
      type: Array,
      default: function () { },
    },
    untia: {
      type: String,
      default: "",
    },
    createDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      indexlist: [0, 1, 2],
      defatindex: 0,
      profitablitylist2: [
        {
          lists: [
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
          ],
          name: "Return on Equity:",
        },
        {
          lists: [
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
          ],
          name: "Return on Assetsd:",
        },
        {
          lists: [
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
          ],
          name: "",
        },
      ],
      // createDialog: true,
      years: ["", "", ""],
      checkedCities: ["", "", ""],
      windows: window,
      moreclose: false,
    };
  },
  created() { },

  watch: {
    $route: {
      handler(val, oldval) {
        this.Indexy = val.path.replace("/", ""); //当前活动路由
      },
      // 深度观察监听
      deep: true,
    },
    profitablitylist: {
      handler(val, oldval) {
        this.profitablitylist = val;
      },
      deep: true,
    },
    yearslist: {
      handler(val, oldval) {
        this.yearslist = val;
        this.getyears();
      },
      deep: true,
    },
  },
  mounted() {
    this.getyears();
  },

  methods: {
    
    More() {
      this.moreclose = true;
    },
    closmoreDialog(value) {
      this.moreclose = value;
    },
    getyears() {

      this.checkedCities = this.yearslist.slice(0, 3);
      var lis = [];
      this.checkedCities.map((item) => {
        lis.push(item);
      });

      this.years = lis.sort(function (a, b) {
        return b - a;
      });
    },
    checkYear() {
      var list = [];
      this.checkedCities.map((item) => {
        list.push(item);
      });

      this.years = list.sort(function (a, b) {
        return b - a;
      });

      this.indexlist = [];
      this.yearslist.map((item, index) => {
        this.checkedCities.map((ite, inde) => {
          if (item == ite) {
            this.indexlist.push(index);
          }
        });
      });
      this.$emit("createDialogcheck", false);
    },
    Cancel() {
      this.$emit("createDialogcheck", false);
    },
  },
};
</script>
<style scoped lang="less">
// 表格
.td {
  margin: 1.0714285714285714rem 0 0 1.7857142857142858rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rightBox.leftBox {
  display: inline-block;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.changeBox {
  display: flex;
  align-items: center;
}

.block13 {
  // margin: 1.0714285714285714rem 0.7142857142857143rem 0 1.7857142857142858rem;
}

.thead {
  background: #f2f5f9;
  z-index: 405;
  height: 3.5714285714285716rem;
  line-height: 3.5714285714285716rem;
  padding-right: 1.4285714285714286rem;
  position: relative;
  border-bottom: 0.07142857142857142rem solid #cccccc;
}
.span_{
height: max-content;
   display: flex;
   align-items: center;
   font-family: 'DMSans Bold';

}
@media screen and (max-width: 1441px) {
  .thead {
    height: 30px !important;
    line-height: 30px !important;
  }
 

  .td-width{
    font-size: 14px !important;
    height: 30px;
  }

  .thead-title,.td-width-span{
    height: 40px  !important;
    line-height: 40px  !important;
  }
  .span_{
    height: 30px  !important;
    line-height: 30px  !important;
  }
}
.thead .td-width:first-child {
  border-bottom: 0.07142857142857142rem solid #cccccc;
}

.tbody {
  z-index: 405;
  padding-right: 1.4285714285714286rem;
  position: relative;
  border-bottom: 0.07142857142857142rem solid #cccccc;
}

.thead-img {
  width: 2.142857142857143rem;
  height: 2.142857142857143rem;
  background: #8497ab;
  border-radius: 50%;
  text-align: center;
  line-height: 2.142857142857143rem;
  position: relative;
  top: -0.35714285714285715rem;
}

.td-width {
  // flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'DMSans Bold';
  font-size: 1.4285714285714286rem;
  color: #1a2332;
}

.table-tbody {
  position: relative;
}

.juli {
  margin-left: 2.142857142857143rem;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.table-biao {
  // height: 24.285714285714285rem;
  height: auto;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 0.2857142857142857rem;
  margin-top: 1.4285714285714286rem;
}

.zhongjian {
  display: flex;
  justify-content: space-between;
  width: 22%;
}

.td-width-span {
  // flex: 1;
  // height: 5.714285714285714rem;
  // line-height: 5.714285714285714rem;
  // text-align: center;
}

.td-width-txt {
  text-align: right;
  width: 9.285714285714286rem;
  text-align: center;
  white-space: nowrap;
  /* 防止换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 显示省略号 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-checkbox-group {
  // display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
}

// .el-checkbox {
//     margin-bottom: 1.0714285714285714rem;
//     margin-left: 2.857142857142857rem;
//     margin-right: 0;
// }
/deep/.el-checkbox__label {
  color: #022955ff !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  color: #022955ff !important;
}

/deep/ .el-checkbox-button__inner {
  // border-left: 0.07142857142857142rem solid;
  border: none;
  padding: 0.7142857142857143rem 1.0714285714285714rem;
  font-size: 1.2857142857142858rem;
  color: #1a2332;
  background: #f5f5f5;
  border-radius: 0.2857142857142857rem;
  width:8.28571429rem;
}

/deep/ .el-checkbox-button {
  margin-right: 6px;
  margin-bottom: 1rem;
}

/deep/ .el-checkbox-button:nth-of-type(6n + 6) {
  margin-right: 0rem !important;
}

/deep/ .el-checkbox-button:first-child .el-checkbox-button__inner {
  border: none;
}

/deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background: #ffffff;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  border-radius: 0.2857142857142857rem;
  font-size: 1.2857142857142858rem;
  color: #1a2332;
  border: 0.07142857142857142rem solid #1290c9;
}

.thead-title {
  height: 5.714285714285714rem;
  line-height: 5.714285714285714rem;
}

.left-title {
  background: #f2f5f9ff;
  padding-left: 1.4285714285714286rem;
  padding-right: 1.4285714285714286rem;
  width: 26.57rem;
}

.dialog_detail_title {
  font-family: 'DMSans Bold';
  font-size: 1.4285714285714286rem;
  color: #1a2332;
}

.dialog_detail_next-title {
  font-size: 1rem;
  color: #8497ab;
  line-height: 1.1428571428571428rem;
  text-align: left;
  height: 1.2857142857142858rem;
  margin-top: 0.42857142857142855rem;
  margin-bottom: 1.4285714285714286rem;
}

/deep/ .el-checkbox-button.is-disabled .el-checkbox-button__inner {
  background: #f5f5f5;
  border-radius: 0.2857142857142857rem;
}

/deep/ .el-checkbox-group {
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
}

.cansel {
  background-color: #8497ab !important;
}
</style>
